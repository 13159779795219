import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'
import { useInView } from 'react-intersection-observer'
import { BackgroundImage, LinkLocalized } from '../../../../../component'

import style from './program-card.module.scss'

const renderProgramDetails = (program, goal, programLogo) => (
    <>
        <div
            className={style.logo}
            style={{ backgroundImage: `url(${programLogo})` }}
        />
        <span className={style.property}>{goal.name}</span>
        <span className={style.property}>
            <FormattedMessage id={`home.program-card.level.${program.level}`} />
        </span>
        <span className={style.property}>
            <FormattedMessage
                id="home.program-card.week.dynamic"
                values={{ duration: program.durationWeeks }}
            />
        </span>
    </>
)

// eslint-disable-next-line react/prop-types
export const WithImage = ({ image, children, imageFallback, ...rest }) =>
    image ? (
        <BackgroundImage {...rest} fluid={image}>
            {children}
        </BackgroundImage>
    ) : (
        <div
            className={cn(style.image, style.imageFallback)}
            style={{
                backgroundImage: `url(${imageFallback})`,
            }}
        >
            {children}
        </div>
    )

const ProgramCard = ({ program }) => {
    const backgroundImage = _.get(
        program,
        'details.imagesNode.background.childImageSharp.fluid'
    )
    const imageUrl = _.get(program, 'imageUrl')
    const programLogo = `${imageUrl}webapp-titletext.svg`
    const backgroundImgFallback = `${imageUrl}webapp-detail-1080.jpg`

    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: '100px',
    })
    const goal = _.get(program, 'goal')

    return (
        <LinkLocalized
            innerRef={ref}
            to="programs"
            view={program.slug}
            className={cn(
                'col-xs-12 col-sm-12 col-md-6 col-lg-4',
                style.wrapper
            )}
        >
            <div
                className={style.imageWrap}
                style={{
                    borderColor: `#${goal.color}`,
                    backgroundColor: inView ? 'unset' : `#${goal.color}`,
                }}
            >
                {inView ? (
                    <>
                        {program.new && (
                            <div className={style.new}>
                                <FormattedMessage id="home.program-card.new" />
                            </div>
                        )}
                        <WithImage
                            classParentName={style.image}
                            image={backgroundImage}
                            imageFallback={backgroundImgFallback}
                        >
                            <div className={style.programDetailsWrapper}>
                                {renderProgramDetails(
                                    program,
                                    goal,
                                    programLogo
                                )}
                            </div>
                        </WithImage>
                        <div className={cn(style.overlay)} />
                        <div
                            className={style.productDetailsHover}
                            style={{ backgroundColor: `#${goal.color}` }}
                        >
                            <div className={style.hoverContentWrapper}>
                                {renderProgramDetails(
                                    program,
                                    goal,
                                    programLogo
                                )}
                                <div className={style.headline}>
                                    {program.headline}
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </div>
        </LinkLocalized>
    )
}

ProgramCard.propTypes = {
    program: PropTypes.shape({
        color: PropTypes.string,
        name: PropTypes.string,
        headline: PropTypes.string,
        slug: PropTypes.string,
        new: PropTypes.bool,
    }).isRequired,
}

export default ProgramCard
