import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Tracker } from '@gymondo/frontend-core/utils'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import { InputLabel } from '@gymondo/frontend-core/components'
import { changeFilter } from '../../service'

import style from './filter.module.scss'

const renderField = (field, dispatch) => (
    <div className={cn(style.checkbox, 'checkbox-container')} key={field.name}>
        <input
            className={cn(style.checkbox, 'checkbox checkbox--tertiary')}
            id={field.name}
            type="checkbox"
            checked={field.selected}
            onChange={() => dispatch(changeFilter(field.name))}
            {...(!field.selected
                ? {}
                : {
                      'data-evt': Tracker.EVENT_TYPES.CHANGE,
                      'data-category': 'homepage',
                      'data-action': 'homepage_use_filter_option',
                      'data-label': field.category,
                      'data-value': field.value,
                  })}
        />
        <InputLabel
            className={cn('display--inline-block')}
            htmlFor={field.name}
        >
            <FormattedMessage
                id={`home.grid-filter-bar.filter.${field.name}.label`}
            />
        </InputLabel>
    </div>
)

const Filters = ({ filters }) => {
    const groupedFilters = _.groupBy(filters, 'category')
    const dispatch = useDispatch()

    return (
        <div className={style.categoriesWrapper}>
            {Object.keys(groupedFilters).map(key => (
                <div className={style.categoryWrapper} key={key}>
                    <div className={style.categoryTitle}>
                        <FormattedMessage
                            id={`home.grid-filter-bar.${key}.title`}
                        />
                    </div>
                    {groupedFilters[key].map(field =>
                        renderField(field, dispatch)
                    )}
                </div>
            ))}
        </div>
    )
}

Filters.propTypes = {
    filters: PropTypes.array.isRequired,
}

export default Filters
