import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import smileySvg from '@gymondo/frontend-core/svg/smiley-sad.svg'
import { Icon } from '@gymondo/frontend-core/components'
import GridSortBar from '../grid-sort-bar'
import GridFilterBar from '../grid-filter-bar'
import ProgramCard from '../program-card'
import LearnMoreCard from '../learn-more-card'
import * as programService from '../../service'

import style from './program-grid.module.scss'

const renderNoPrograms = () => (
    <div className={style.noProgramsWrapper}>
        <Icon data={smileySvg} className={style.noProgramsIcon} />
        <span className={style.noProgramsTitle}>
            <FormattedMessage id="home.program-grid.noPrograms.title" />
        </span>
        <span className={style.noProgramsSubtitle}>
            <FormattedMessage id="home.program-grid.noPrograms.subtitle" />
        </span>
    </div>
)

export const intersectPrograms = (fetchedPrograms, preFetchedPrograms) =>
    fetchedPrograms
        .map((fetchedProgram) =>
            preFetchedPrograms.find((cachedProgram) =>
                cachedProgram.id.endsWith(`_${fetchedProgram.id}`)
            )
        )
        .filter((it) => it)

export const ProgramGrid = ({
    sort,
    setSort,
    filters,
    toggleDrawer,
    data,
    preFetchedPrograms,
    drawerOpen,
}) => {
    const programs = useMemo(
        () =>
            data && data.content
                ? intersectPrograms(data.content, preFetchedPrograms)
                : preFetchedPrograms.slice(0, 50),
        [data, preFetchedPrograms]
    )

    const activeFiltersLength = filters
        ? filters.filter((filter) => filter.selected).length
        : 0

    return (
        <>
            <div className={style.wrapper}>
                <GridSortBar
                    sortValue={sort}
                    onSortChange={setSort}
                    totalNumber={
                        activeFiltersLength > 0
                            ? programs.length
                            : preFetchedPrograms.length
                    }
                    activeFiltersNumber={activeFiltersLength}
                    toggleDrawer={toggleDrawer}
                />
                <GridFilterBar filters={filters} drawerOpen={drawerOpen} />
            </div>

            {programs.length === 0 && renderNoPrograms()}

            <div className={cn('row', style.cardsWrapper)}>
                {programs.length > 0 && <LearnMoreCard />}
                {programs.map((program, index) => (
                    <ProgramCard
                        program={program}
                        key={`${index}_${program.id}`}
                    />
                ))}
            </div>
        </>
    )
}

ProgramGrid.propTypes = {
    sort: PropTypes.string,
    setSort: PropTypes.func.isRequired,
    filters: PropTypes.array,
    toggleDrawer: PropTypes.func.isRequired,
    data: PropTypes.object,
    preFetchedPrograms: PropTypes.array.isRequired,
    drawerOpen: PropTypes.bool,
}

ProgramGrid.defaultProps = {
    sort: '',
    filters: [],
    data: null,
    drawerOpen: false,
}

export const mapStateToProps = (state) => ({
    ...state.landingPrograms,
})

export default connect(mapStateToProps, {
    setSort: programService.setSort,
    toggleDrawer: programService.toggleDrawer,
})(ProgramGrid)
