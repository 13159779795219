import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import { Icon } from '@gymondo/frontend-core/components'
import { mediaXsOnly } from '@gymondo/frontend-core/style-vars'
import { useMediaQuery } from '@gymondo/frontend-core/hooks'

import filterIcon from '@gymondo/frontend-core/svg/u-filter.svg'

import { SORT } from '../../conf/sort.conf'

import style from './grid-sort-bar.module.scss'

const options = [
    { label: 'home.grid-sort-bar.publishedOn.label', value: SORT.PUBLISHED_ON },
    { label: 'home.grid-sort-bar.popularity.label', value: SORT.POPULARITY },
]

const renderTitle = (totalNumber) => (
    <div className={style.titleWrapper}>
        <span className={style.title}>
            <FormattedMessage id="home.grid-sort-bar.title" />
        </span>
        <span className={style.total}>{`(${totalNumber})`}</span>
    </div>
)

const renderFilterButton = (activeFiltersNumber, toggleDrawer) => (
    <button
        className={style.filterButton}
        type="button"
        onClick={() => toggleDrawer(true)}
    >
        <Icon data={filterIcon} className={style.filterIcon} />
        <FormattedMessage id="home.grid-filter-bar.title" />
        {activeFiltersNumber > 0 && ` (${activeFiltersNumber})`}
    </button>
)

const GridSortBar = ({
    totalNumber,
    onSortChange,
    activeFiltersNumber,
    toggleDrawer,
    sortValue,
}) => {
    const intl = useIntl()
    const isXsOnly = useMediaQuery(mediaXsOnly)

    return (
        <div className={cn(style.wrapper, 'row')}>
            {renderTitle(totalNumber)}
            {isXsOnly && renderFilterButton(activeFiltersNumber, toggleDrawer)}

            <div className={cn(style.sorterWrapper, 'col-sm-6 col-xs-12')}>
                {!isXsOnly && (
                    <div className={style.sorterLabel}>
                        <FormattedMessage id="home.grid-sort-bar.label" />
                    </div>
                )}
                <select
                    className="select"
                    value={sortValue}
                    onChange={(e) => onSortChange(e.target.value)}
                >
                    {isXsOnly && (
                        <option
                            value=""
                            disabled
                            style={{ display: 'none' }}
                            className={style.disabledOption}
                        >
                            {intl.formatMessage({
                                id: 'home.grid-sort-bar.label',
                            })}
                        </option>
                    )}
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {intl.formatMessage({ id: option.label })}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    )
}

GridSortBar.propTypes = {
    totalNumber: PropTypes.number.isRequired,
    onSortChange: PropTypes.func.isRequired,
    activeFiltersNumber: PropTypes.number,
    toggleDrawer: PropTypes.func.isRequired,
    sortValue: PropTypes.string,
}

GridSortBar.defaultProps = {
    activeFiltersNumber: 0,
    sortValue: '',
}

export default GridSortBar
