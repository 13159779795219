import React from 'react'
import _ from 'lodash'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'
import { Icon } from '@gymondo/frontend-core/components'
import { Tracker } from '@gymondo/frontend-core/utils'
import arrowRightIcon from '@gymondo/frontend-core/svg/arrow-bold.svg'
import { useStaticQuery, graphql } from 'gatsby'
import { ROUTES_ID } from '../../../../../config/route'
import { LinkLocalized } from '../../../../../component'
import { WithImage } from '../program-card/program-card'

import styleCard from '../program-card/program-card.module.scss'
import style from './learn-more-card.module.scss'

const LearnMoreCard = () => {
    const data = useStaticQuery(graphql`
        query {
            allFile(
                filter: {
                    relativePath: {
                        eq: "page/generic/home/component/learn-more-card/static/image/background.png"
                    }
                }
            ) {
                edges {
                    node {
                        childImageSharp {
                            fluid(
                                maxWidth: 1400
                                srcSetBreakpoints: [430]
                                toFormat: NO_CHANGE
                                quality: 90
                            ) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    `)

    const backgroundImage = _.get(
        data,
        'allFile.edges.0.node.childImageSharp.fluid'
    )

    return (
        <LinkLocalized
            to={ROUTES_ID.HOW_IT_WORKS}
            className={cn('col-xs-12 col-md-6 col-lg-4', styleCard.wrapper)}
            data-evt={Tracker.EVENT_TYPES.CLICK}
            data-category="homepage"
            data-action="content_click_how_it_works"
        >
            <div className={cn(styleCard.imageWrap, style.imageWrap)}>
                <WithImage
                    classParentName={cn(styleCard.image, style.image)}
                    image={backgroundImage}
                />
                <div
                    className={cn(
                        styleCard.hoverContentWrapper,
                        style.hoverContentWrapper
                    )}
                >
                    <div className={style.title}>
                        <FormattedMessage id="home.learn-more-card.title" />
                    </div>
                    <div className={style.description}>
                        <FormattedMessage id="home.learn-more-card.description" />
                    </div>
                    <div className={style.action}>
                        <FormattedMessage id="home.learn-more-card.learn-more" />
                        <Icon
                            data={arrowRightIcon}
                            className={style.arrowIcon}
                        />
                    </div>
                </div>
            </div>
        </LinkLocalized>
    )
}

export default LearnMoreCard
