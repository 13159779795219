import { and, comparison, eq, inList, outList } from 'rsql-builder'
import _ from 'lodash'
import URL from '../../../../config/url'
import CRUD from '../../../../api/generic/crud/crud'
import * as CONSTANTS from './program.constants'
import { SORT } from '../conf/sort.conf'

const landingProgramsSuccess = (payload) => ({
    type: CONSTANTS.LANDING_PROGRAMS_SUCCESS,
    payload,
})

const landingProgramsPending = () => ({
    type: CONSTANTS.LANDING_PROGRAMS_PENDING,
})

export const toggleDrawer = (open) => ({
    type: CONSTANTS.LANDING_PROGRAMS_TOGGLE_DRAWER,
    payload: open,
})

export const _buildQueryParams = (filters = [], sorter) => {
    const filtered = filters.filter((item) => item.selected === true)
    const group = _.groupBy(filtered, 'category')
    const filtersRsqlQuery = Object.keys(group).map((key) =>
        comparison(key, inList(...group[key].map((i) => i.value)))
    )
    const query = and(
        comparison('express', eq(false)),
        comparison('id', outList(230, 211, 216, 217, 218, 219)),
        ...filtersRsqlQuery
    )
    const sorterWithFallback = sorter || SORT.PUBLISHED_ON

    return {
        page: 0,
        q: query,
        size: 50,
        [`${sorterWithFallback}.dir`]: 'desc',
        sort: `${sorterWithFallback}`,
    }
}

export const getPrograms = () => async (dispatch, getState) => {
    const currentState = getState()[CONSTANTS.NAME]
    const params = _buildQueryParams(currentState.filters, currentState.sort)
    dispatch(landingProgramsPending())
    const { data } = await CRUD.get(URL.publicPrograms, { params })
    dispatch(landingProgramsSuccess(data))
}

export const setSort = (sort) => (dispatch) => {
    dispatch({
        type: CONSTANTS.LANDING_PROGRAMS_SORT,
        payload: sort,
    })
    dispatch(getPrograms())
}

export const changeFilter = (filter) => (dispatch) => {
    dispatch({
        type: CONSTANTS.LANDING_PROGRAMS_CHANGE_FILTER,
        payload: filter,
    })
    dispatch(getPrograms())
}

export const resetFilters = () => (dispatch) => {
    dispatch({
        type: CONSTANTS.LANDING_PROGRAMS_RESET_FILTERS,
    })
    dispatch(getPrograms())
}
