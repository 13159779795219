import React from 'react'
import PropTypes from 'prop-types'
import { Tracker } from '@gymondo/frontend-core/utils'
import closeIcon from '@gymondo/frontend-core/svg/u-close.svg'
import cn from 'classnames'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Icon, DragDrawer } from '@gymondo/frontend-core/components'
import { mediaXsOnly } from '@gymondo/frontend-core/style-vars'
import { useMediaQuery } from '@gymondo/frontend-core/hooks'

import Filters from '../filter/filter'

import style from './grid-filter-bar.module.scss'
import { resetFilters, toggleDrawer } from '../../service'

const TRACKING_LABELS = {
    BACKGROUND_TAP: 'background_tap',
    PULL_DOWN: 'pull_down',
    BUTTON: 'done_button',
}

const track = (trigger, drawerState) => {
    Tracker.push({
        event: Tracker.EVENTS.TRACKING,
        eventCategory: 'homepage',
        eventAction: drawerState
            ? 'homepage_program_filter_open'
            : 'homepage_program_filter_close',
        ...(trigger && { eventLabel: trigger }),
    })
}

const renderResetButton = (filters, dispatch) => {
    const hasSelectedFilter = filters.find(filter => filter.selected)
    const handleClick = () => {
        if (hasSelectedFilter) {
            return dispatch(resetFilters())
        }

        return null
    }

    return (
        <div
            className={cn(
                style.button,
                !hasSelectedFilter && style.buttonDisabled
            )}
            onClick={handleClick}
        >
            <Icon data={closeIcon} className={style.resetIcon} />
            <FormattedMessage id="home.grid-filter-bar.reset.button" />
        </div>
    )
}

// eslint-disable-next-line react/prop-types
const WithDrawer = ({ matches, children, show, onClose }) =>
    matches ? (
        <DragDrawer
            className={style.drawer}
            show={show}
            onClose={onClose}
            onOpen={() => track(null, true)}
            onDragClose={() => track(TRACKING_LABELS.PULL_DOWN)}
            onParentClick={() => track(TRACKING_LABELS.BACKGROUND_TAP)}
            modalClassName={style.modal}
        >
            <div className={style.modalTop}>
                <div className={style.label}>
                    <FormattedMessage id="home.grid-filter-bar.title" />
                </div>
                <button
                    type="button"
                    className={style.button}
                    onClick={() => {
                        track(TRACKING_LABELS.BUTTON)
                        onClose()
                    }}
                >
                    <FormattedMessage id="home.grid-filter-bar.done.button" />
                </button>
            </div>
            {children}
        </DragDrawer>
    ) : (
        <>{children}</>
    )

export const GridFilterBar = ({ filters, drawerOpen }) => {
    const matches = useMediaQuery(mediaXsOnly)
    const dispatch = useDispatch()
    const closeDrawer = () => dispatch(toggleDrawer(false))

    return (
        <div className={style.wrapper}>
            <WithDrawer
                matches={matches}
                show={drawerOpen}
                onClose={closeDrawer}
            >
                <Filters filters={filters} />
                {renderResetButton(filters, dispatch)}
            </WithDrawer>
        </div>
    )
}

GridFilterBar.propTypes = {
    filters: PropTypes.array.isRequired,
    drawerOpen: PropTypes.bool.isRequired,
}

export default GridFilterBar
